import React, { useState, useEffect } from 'react';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


import { Typography } from '@mui/material';
import TLHClassesForEachDay from 'theLearningHub/layouts/HelperComponents/TLHClassesForEachDay';
import CreateNewClassModel from 'theLearningHub/layouts/HelperComponents/CreateNewClassModel';
import TLHClassesAPICalls from 'services/TLHClassesAPICalls';
import TLHEndpoints from 'services/TLHEndpoints';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddTLHStaffMember from 'theLearningHub/layouts/HelperComponents/AddTLHStaffMember';


export default function TLHStaffMembers() {

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const [addNewTLHStaffModelIsOpen, setAddNewTLHStaffModelIsOpen] = useState(false);
  const [staffMemberInformation, setStaffMemberInformation] = useState()

  const handleOpenAddNewTLHStaffModel = () => setAddNewTLHStaffModelIsOpen(true);
  // const handleCloseAddNewTLHStaffModel = () => setAddNewTLHStaffModelIsOpen(false);
  
  function handleCloseAddNewTLHStaffModel() {
    setStaffMemberInformation(null)
    setAddNewTLHStaffModelIsOpen(false);
  }

  const [isLoadingTLHStaffMembers, setIsLoadingTLHStaffMembers] = useState(true)
  const [tlhStaffData, setTLHStaffData] = useState([])


  useEffect(() => {
    if (isLoadingTLHStaffMembers) {
      getAllTLHStaffMembers()
    }
  }, [tlhStaffData]);

  function getAllTLHStaffMembers() {
    console.log("Making request")
    TLHEndpoints.getAllTLHStaffMembers().then(result => {
      console.log(result.data)
      // var sortedLessons = sortClassesByDay(result.data)
      setTLHStaffData(result.data)
      setIsLoadingTLHStaffMembers(false)
    })
  }

  function viewOrAddTLHStaffDetails(tlhStaffData) {
    if (tlhStaffData) {
      setStaffMemberInformation(tlhStaffData)
    } 
    handleOpenAddNewTLHStaffModel()
  }



  return (
    
    <div>
       <div style={{ overflowX: 'auto', width: '100%'}}>
          <Typography variant="h5" align='center' style={{padding: "15px"}}>
            TLH Staff
          </Typography>
        </div>

      {isLoadingTLHStaffMembers ? 
      <>
        <Typography variant="h6" align='center' style={{padding: "15px"}}>
            Loading Staff Data ...
        </Typography>
      </> :
        <>
          <Table size="small" style={{tableLayout: 'auto'}}>
            <TableHead >
              <TableRow>
                <TableCell>Staff Name</TableCell>
                <TableCell>Work Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Staff Type</TableCell>
                <TableCell>DBS Completion Date</TableCell>
                <TableCell>Has signed TLH Ts and Cs</TableCell>
                <TableCell>Staff Hours</TableCell>

      
              </TableRow>
            </TableHead>
            <TableBody style={{textAlign: "center"}}>
            {tlhStaffData.map((tlhStaff, tlhStaffIndex) => (
                <TableRow key={tlhStaff.staffId}>
                  <TableCell>
                    <button 
                      onClick={() => viewOrAddTLHStaffDetails(tlhStaff)}
                    > 
                      <u>{tlhStaff.firstName + " " + tlhStaff.lastName}</u>
                    </button>
                  </TableCell>
                  <TableCell>{tlhStaff.workEmail}</TableCell>
                  <TableCell>{tlhStaff.phoneNumber}</TableCell>
                  <TableCell>Staff type</TableCell>
                  <TableCell>{tlhStaff?.dbsCompletionDate ? tlhStaff.dbsCompletionDate : "Not Done"}</TableCell>
                  <TableCell>{tlhStaff.hasSignedTLHTermsAndConditions ? "Yes" : "No"}</TableCell>
                  <TableCell><u>Click to view</u></TableCell>


                </TableRow>
              ))} 
            </TableBody>
          </Table>
        </>
      }

      <div style={{textAlign: 'center', padding: '30px'}}>
        <button 
            style={{
              padding: '10px 20px',
              fontSize: '18px',
              color: 'white',
              backgroundColor: '#0056b3',
              border: 'none',
              borderRadius: '5px',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
          }}
          onMouseOver={(e) => {
              e.target.style.backgroundColor = '#004085';
              e.target.style.boxShadow = '0 8px 16px rgba(0,0,0,0.2)';
          }}
          onMouseOut={(e) => {
              e.target.style.backgroundColor = '#0056b3';
              e.target.style.boxShadow = '0 4px 8px rgba(0,0,0,0.1)';
          }}
          onClick={handleOpenAddNewTLHStaffModel}
          type="submit"
        >
            Add New TLH Staff Member
        </button>
      </div>



      <Modal
          open={addNewTLHStaffModelIsOpen}
          onClose={handleCloseAddNewTLHStaffModel}
          // aria-labelledby="modal-add-mock-exam"
          // aria-describedby="modal-able-to-add-a-set"
        >
          <Box sx={style}>
            <AddTLHStaffMember
              handleClose={handleCloseAddNewTLHStaffModel}
              staffMemberInformation={staffMemberInformation}
            />
          </Box>
        </Modal>
      
    </div>
    
  );
}

