import React, { useRef, useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import TLHClassesAPICalls from 'services/TLHClassesAPICalls';

export default function IndividualTLHStudentProfile() {
  const [isGettingStudentInfo, setIsGettingStudentInfo] = useState(true);
  const [tlhStudentData, setTLHStudentData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);
  const [newNote, setNewNote] = useState('');

  const { tlhStudentId } = useParams();

  useEffect(() => {
    if (isGettingStudentInfo) {
      getIndividualStudentInfo();
    }
  }, [isGettingStudentInfo]);

  const getIndividualStudentInfo = async () => {
    const result = await TLHClassesAPICalls.getIndividualTLHStudentInfo(tlhStudentId);
    setTLHStudentData(result.data);
    setIsGettingStudentInfo(false);
  };

  const addNoteToTLHStudent = async () => {
    console.log("About to make call to add note")
    const result = await TLHClassesAPICalls.addNoteToTLHStudent(tlhStudentId, newNote);
    console.log(result)
    setNewNote('');
    setIsGettingStudentInfo(true);
  }

  function reverseYearGroupEnum(yearGroupEnum) {

    switch (yearGroupEnum) {
      case "YEAR_0":
      case "YEAR_1":
      case "YEAR_2":
      case "YEAR_3":
      case "YEAR_4":
      case "YEAR_5":
      case "YEAR_6":
        return parseInt(yearGroupEnum.split('_')[1], 10);
      case "KS3_A":
        return 7;
      case "KS3_B":
        return 8;
      case "KS3_C":
        return 9;
      case "GCSE_1":
        return 10;
      case "GCSE_2":
        return 11;
      case "AS_LEVEL":
        return 12;
      case "A2_LEVEL":
        return 13;
      default:
        return 14;
    }
  }
  

  const handleToggleEditMode = (event) => {
    event.preventDefault();
    setIsEditMode(!isEditMode);
  };

  const handleSave = async (values) => {
    const updatedValues = {
      ...values,
      yearGroupEnums: `YEAR_${values.yearGroupEnums}`,
      parentFirstName: values.parentName.split(' ')[0],
      parentLastName: values.parentName.split(' ')[1],
      communicationNotes: [
        ...tlhStudentData.communicationNotes,
        ...(newNote ? [{ noteCreationTimestamp: new Date().toISOString(), communicationNote: newNote }] : [])
      ],
    };
    await TLHClassesAPICalls.editIndividualTLHStudentInfo(tlhStudentId, updatedValues, tlhStudentData.assessmentInformation.parentBookingEnquiryDetails.enquiryAndAssessmentBookingId);
    setNewNote('');
    setIsEditMode(false);
    setIsGettingStudentInfo(true);
  };

  const validationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    surname: yup.string().required('Surname is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    parentName: yup.string().required('Parent Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      ...tlhStudentData,
      parentName: tlhStudentData
        ? `${tlhStudentData.assessmentInformation.parentBookingEnquiryDetails.parentFirstName} ${tlhStudentData.assessmentInformation.parentBookingEnquiryDetails.parentLastname}`
        : '',
      yearGroupEnums: tlhStudentData
        ? parseInt(tlhStudentData.yearGroupEnums.split('_')[1], 10)
        : '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => handleSave(values),
  });

  const formatString = (str) => {
    if (!str) return "N/A";
    return str
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  return (
    <div style={{ padding: '20px' }}>
      {isGettingStudentInfo ? (
        <div>Loading Data...</div>
      ) : (
        <form onSubmit={formik.handleSubmit}>
          
          <Typography variant="h4" align="center" style={{ paddingBottom: "20px" }}>
            {isEditMode ? (
              <TextField
                id="firstName"
                name="firstName"
                label="First Name"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                helperText={formik.touched.firstName && formik.errors.firstName}
                style={{ marginRight: "10px" }}
              />
            ) : (
              <span style={{ marginRight: "10px" }}>{tlhStudentData.firstName}</span>
            )}
            {isEditMode ? (
              <TextField
                id="surname"
                name="surname"
                label="Surname"
                value={formik.values.surname}
                onChange={formik.handleChange}
                error={formik.touched.surname && Boolean(formik.errors.surname)}
                helperText={formik.touched.surname && formik.errors.surname}
              />
            ) : (
              tlhStudentData.surname
            )}
          </Typography>

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Parent Name:</Typography>
            {isEditMode ? (
              <TextField
                id="parentName"
                name="parentName"
                label="Parent Name"
                value={formik.values.parentName}
                onChange={formik.handleChange}
                error={formik.touched.parentName && Boolean(formik.errors.parentName)}
                helperText={formik.touched.parentName && formik.errors.parentName}
                fullWidth
              />
            ) : (
              tlhStudentData.assessmentInformation.parentBookingEnquiryDetails.parentFirstName + " " + tlhStudentData.assessmentInformation.parentBookingEnquiryDetails.parentLastname
            )}
          </div>

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Parent Email:</Typography>
            {isEditMode ? (
              <TextField
                id="parentEmail"
                name="parentEmail"
                label="Parent Email"
                value={formik.values.parentEmail}
                onChange={formik.handleChange}
                fullWidth
              />
            ) : (
              tlhStudentData.parentEmail
            )}
          </div>

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Parent Phone Number:</Typography>
            {isEditMode ? (
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                value={formik.values.phoneNumber}
                onChange={formik.handleChange}
                error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                fullWidth
              />
            ) : (
              tlhStudentData.phoneNumber
            )}
          </div>

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Student Status:</Typography>
            {isEditMode ? (
              <TextField
                id="studentStatus"
                name="studentStatus"
                label="Student Status"
                value={formik.values.studentStatus}
                onChange={formik.handleChange}
                fullWidth
              />
            ) : (
              formatString(tlhStudentData.studentStatus)
            )}
          </div>

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Primary School:</Typography>
            {isEditMode ? (
              <TextField
                id="primarySchool"
                name="primarySchool"
                label="Primary School"
                value={formik.values.primarySchool}
                onChange={formik.handleChange}
                fullWidth
              />
            ) : (
              tlhStudentData.primarySchool
            )}
          </div>

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Secondary School:</Typography>
            {isEditMode ? (
              <TextField
                id="secondarySchool"
                name="secondarySchool"
                label="Secondary School"
                value={formik.values.secondarySchool || ''}
                onChange={formik.handleChange}
                fullWidth
              />
            ) : (
              tlhStudentData.secondarySchool || 'N/A'
            )}
          </div>

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Year Group:</Typography>
            {isEditMode ? (
              <TextField
                id="yearGroupEnums"
                name="yearGroupEnums"
                label="Year Group"
                type="number"
                value={formik.values.yearGroupEnums}
                onChange={formik.handleChange}
                fullWidth
              />
            ) : (
              reverseYearGroupEnum(tlhStudentData.yearGroupEnums)
            )}
          </div>

{/*  Will use this when we open up another centre
          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>TLH Centre:</Typography>
            {isEditMode ? (
              <TextField
                id="tlhCentre"
                name="tlhCentre"
                label="TLH Centre"
                value={formik.values.tlhCentre}
                onChange={formik.handleChange}
                fullWidth
              />
            ) : (
              tlhStudentData.tlhCentre
            )}
          </div> */}

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Date of Birth:</Typography>
            {isEditMode ? (
              <TextField
                id="dateOfBirth"
                name="dateOfBirth"
                label="Date of Birth"
                type="date"
                value={formik.values.dateOfBirth || ''}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              tlhStudentData.dateOfBirth || 'N/A'
            )}
          </div>

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Join Date:</Typography>
            {isEditMode ? (
              <TextField
                id="joinDate"
                name="joinDate"
                label="Join Date"
                type="date"
                value={formik.values.joinDate || ''}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              tlhStudentData.joinDate
            )}
          </div>

          <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between' }}>
            <Typography>Leaving Date:</Typography>
            {isEditMode ? (
              <TextField
                id="leavingDate"
                name="leavingDate"
                label="Leaving Date"
                type="date"
                value={formik.values.leavingDate || ''}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            ) : (
              tlhStudentData.leavingDate
            )}
          </div>


          <div>
            {tlhStudentData.communicationNotes.length === 0 ? (
              <Typography style={{ textAlign: "center", fontWeight: "bold", fontSize: "25px", marginBottom: "20px" }}>
                No communication notes
              </Typography>
            ) : (
              <div>
                <Typography style={{ textAlign: "center", fontWeight: "bolder", fontSize: "x-large", paddingBottom: "20px" }}>
                  Communication Notes
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Note</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tlhStudentData.communicationNotes.map((note) => (
                      <TableRow key={note.noteCreationTimestamp}>
                        <TableCell>{new Date(note.noteCreationTimestamp).toLocaleString()}</TableCell>
                        <TableCell>{note.communicationNote}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </div>

          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            onClick={() => setIsAddNoteModalOpen(true)}
          >
            Add Communication Note
          </Button>

          <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
            {isEditMode ? (
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            ) : (
              <Button variant="contained" color="secondary" onClick={handleToggleEditMode}>
                Edit
              </Button>
            )}
          </div>
        </form>
      )}

      {/* Add Note Modal */}
      <Modal
        open={isAddNoteModalOpen}
        onClose={() => setIsAddNoteModalOpen(false)}
        aria-labelledby="add-note-modal-title"
        aria-describedby="add-note-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="add-note-modal-title" variant="h6" component="h2">
            Add Communication Note
          </Typography>
          <TextField
            id="newNote"
            label="New Note"
            fullWidth
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
            multiline
            rows={4}
            style={{ marginTop: "20px" }}
          />
          <div style={{ marginTop: "20px", display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (newNote.trim()) {
                  addNoteToTLHStudent()
                  setIsAddNoteModalOpen(false);
                }
              }}
            >
              Add Note
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
